<template>
  <ledger-details-stats-skeleton v-if="extendedBlockchainStats.loading" />
  <ledger-details-stats-skeleton v-else-if="extendedBlockchainStats.error" />
  <div v-else class="card-group text-center mb-2">
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-device-ssd
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block"
              >{{
                parseFloat(
                  extendedBlockchainStats.data.blockchain_size || 0
                ).toFixed(2)
              }}
              TB</span
            >
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("ledger_details_stats.blockchain_size") }}</span
            >
          </div>
        </div>
        <div class="flex-shrink-0 d-flex">
          <span
            class="badge bg-opacity-25 align-self-center"
            :class="{
              'text-danger bg-danger':
                extendedBlockchainStats.data.blockchain_size_change < 0,
              'text-secondary bg-secondary':
                extendedBlockchainStats.data.blockchain_size_change === 0,
              'text-success bg-success':
                extendedBlockchainStats.data.blockchain_size_change > 0,
            }"
            ><i
              class="bi"
              :class="{
                'bi-caret-down-fill':
                  extendedBlockchainStats.data.blockchain_size_change < 0,
                'bi-caret-right-fill':
                  extendedBlockchainStats.data.blockchain_size_change === 0,
                'bi-caret-up-fill':
                  extendedBlockchainStats.data.blockchain_size_change > 0,
              }"
            ></i>
            {{ extendedBlockchainStats.data.blockchain_size_change }}%</span
          >
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-hdd-network
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block"
              >{{
                parseFloat(
                  extendedBlockchainStats.data.avg_ledger_size || 0
                ).toFixed(2)
              }}
              MB</span
            >
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("ledger_details_stats.avg_ledger_size") }}</span
            >
          </div>
        </div>
        <div class="flex-shrink-0 d-flex">
          <span
            class="badge bg-opacity-25 align-self-center"
            :class="{
              'text-danger bg-danger':
                extendedBlockchainStats.data.avg_ledger_size_change < 0,
              'text-secondary bg-secondary':
                extendedBlockchainStats.data.avg_ledger_size_change === 0,
              'text-success bg-success':
                extendedBlockchainStats.data.avg_ledger_size_change > 0,
            }"
            ><i
              class="bi"
              :class="{
                'bi-caret-down-fill':
                  extendedBlockchainStats.data.avg_ledger_size_change < 0,
                'bi-caret-right-fill':
                  extendedBlockchainStats.data.avg_ledger_size_change === 0,
                'bi-caret-up-fill':
                  extendedBlockchainStats.data.avg_ledger_size_change > 0,
              }"
            ></i>
            {{ extendedBlockchainStats.data.avg_ledger_size_change }}%</span
          >
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-list-ul
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block">{{
              extendedBlockchainStats.data.average_tps || 0
            }}</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("ledger_details_stats.average_tps") }}</span
            >
          </div>
        </div>
        <div class="flex-shrink-0 d-flex">
          <span
            class="badge bg-opacity-25 align-self-center"
            :class="{
              'text-danger bg-danger':
                extendedBlockchainStats.data.average_tps_change < 0,
              'text-secondary bg-secondary':
                extendedBlockchainStats.data.average_tps_change === 0,
              'text-success bg-success':
                extendedBlockchainStats.data.average_tps_change > 0,
            }"
            ><i
              class="bi"
              :class="{
                'bi-caret-down-fill':
                  extendedBlockchainStats.data.average_tps_change < 0,
                'bi-caret-right-fill':
                  extendedBlockchainStats.data.average_tps_change === 0,
                'bi-caret-up-fill':
                  extendedBlockchainStats.data.average_tps_change > 0,
              }"
            ></i>
            {{ extendedBlockchainStats.data.average_tps_change }}%</span
          >
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-clock-history
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block"
              >{{ extendedBlockchainStats.data.avg_ledger_time || 0 }}s</span
            >
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("ledger_details_stats.avg_ledger_time") }}</span
            >
          </div>
        </div>
        <div class="flex-shrink-0 d-flex">
          <span
            class="badge bg-opacity-25 align-self-center"
            :class="{
              'text-danger bg-danger':
                extendedBlockchainStats.data.avg_ledger_time_change < 0,
              'text-secondary bg-secondary':
                extendedBlockchainStats.data.avg_ledger_time_change === 0,
              'text-success bg-success':
                extendedBlockchainStats.data.avg_ledger_time_change > 0,
            }"
            ><i
              class="bi"
              :class="{
                'bi-caret-down-fill':
                  extendedBlockchainStats.data.avg_ledger_time_change < 0,
                'bi-caret-right-fill':
                  extendedBlockchainStats.data.avg_ledger_time_change === 0,
                'bi-caret-up-fill':
                  extendedBlockchainStats.data.avg_ledger_time_change > 0,
              }"
            ></i>
            {{ extendedBlockchainStats.data.avg_ledger_time_change }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { formatPriceDate } from "@/utils/date.js";
import LedgerDetailsStatsSkeleton from "@/components/skeletons//stats/LedgerDetailsStatsSkeleton.vue";
export default defineComponent({
  name: "LedgerDetailsStats",
  components: { LedgerDetailsStatsSkeleton },
  setup() {
    const store = useStore();
    store.dispatch("analytics/fetchExtendedBlockchainStats");
    const extendedBlockchainStats = computed(
      () => store.getters["analytics/getExtendedBlockchainStats"]
    );
    return { extendedBlockchainStats, formatPriceDate };
  },
});
</script>