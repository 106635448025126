<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <ledger-details-stats />
  <div class="row g-2">
    <div class="col-md-3">
      <div class="card">
        <div class="list-group list-group-flush">
          <div
            v-for="(tab, idx) in navTabs"
            :id="`nav-${tab.name}-tab`"
            :key="idx"
            :class="`list-group-item list-group-item-action
              d-flex
              gap-2
              align-items-center ${tab.name == chartId ? 'active' : ''}`"
            data-bs-toggle="tab"
            :data-bs-target="`#nav-${tab.name}`"
            role="tab"
            :aria-controls="`nav-${tab.name}`"
            :aria-selected="tab.name == chartId ? 'true' : 'false'"
            @click="onTabChange(tab)"
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <i :class="`bi ${tab.icon}`"></i>
              </div>
              <div class="flex-grow-1 ms-3">
                {{ $t(`ledger_details_charts.${tab.name}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="mb-2">
        <blockchain-chart
          :front-page="false"
          :graph="currGraph"
          chart-height="288px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Search from "@/components/Search.vue";
import BlockchainChart from "@/components/charts/BlockchainChart.vue";
import LedgerDetailsStats from "@/components/stats/LedgerDetailsStats.vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "LedgerDetailsCharts",
  components: { Search, BlockchainChart, LedgerDetailsStats },
  setup() {
    const route = useRoute();
    let currGraph = ref("gf_blockchain_size");
    const navTabs = [
      {
        name: "blockchain_size", 
        icon: "bi-device-ssd" },
      {
        name: "avg_ledger_size",
        icon: "bi-hdd-network",
      },
      {
        name: "24h_ledgers",
        icon: "bi-list-ol",
      },
      {
        name: "avg_txns_ledger",
        icon: "bi-list-ol",
      },
      {
        name: "avg_ops_ledger",
        icon: "bi-card-list",
      },
      {
        name: "transactions",
        icon: "bi-list-ul",
      },
      {
        name: "total_no_ops",
        icon: "bi-view-list",
      },
      {
        name: "avg_ledger_time",
        icon: "bi-clock-history",
      },
      {
        name: "total_contract_invocations",
        icon: "bi-clock-history",
      },
      {
        name: "total_new_contracts",
        icon: "bi-clock-history",
      },
    ];
    const onTabChange = (tab) => {
      switch (tab.name) {
        case "blockchain_size":
          currGraph.value = "gf_blockchain_size";
          break;
        case "avg_ledger_size":
          currGraph.value = "gf_avg_ledger_size";
          break;
        case "avg_txns_ledger":
          currGraph.value = "gf_avg_txns_ledger";
          break;
        case "avg_ops_ledger":
          currGraph.value = "gf_avg_ops_ledger";
          break;
        case "transactions":
          currGraph.value = "gf_transactions";
          break;
        case "total_no_ops":
          currGraph.value = "gf_total_no_ops";
          break;
        case "avg_ledger_time":
          currGraph.value = "gf_avg_ledger_time";
          break;
        case "total_contract_invocations":
          currGraph.value = "24h_contract_invocations";
          break;
        case "total_new_contracts":
          currGraph.value = "24h_contract_created";
          break;
        case "24h_ledgers":
          currGraph.value = tab.name;
          break;
      }
    };

    let chartId = 'blockchain_size';
    if (route.params.chartId) {
      chartId = route.params.chartId;
      onTabChange({ name: chartId });
    }

    return {
      navTabs,
      onTabChange,
      currGraph,
      chartId
    };
  },
});
</script>

<style>
</style>
