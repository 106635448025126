<template>
  <div class="card-group text-center mb-2">
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-device-ssd
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block">-</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("ledger_details_stats.blockchain_size") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-hdd-network
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block">-</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("ledger_details_stats.avg_ledger_size") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-list-ul
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block">-</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("ledger_details_stats.average_tps") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body d-flex">
        <div
          class="flex-shrink-0 border border-2 rounded-circle d-flex"
          style="height: 44px; width: 44px"
        >
          <i
            class="
              bi bi-clock-history
              fs-4
              rounded-circle
              align-self-center
              mx-auto
              text-primary
            "
          ></i>
        </div>
        <div class="flex-grow-1 ms-3">
          <div class="float-start text-start">
            <span class="text-black fw-bold mb-0 d-block">-</span>
            <span
              class="text-muted d-block text-uppercase"
              style="font-size: 12px"
              >{{ $t("ledger_details_stats.avg_ledger_time") }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "LedgerDetailsStatsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>